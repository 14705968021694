.home{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

img{
    max-width: 100%;
}

.parallax {
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.parallax-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

/* .parallax-container {
  position: relative;
  overflow: hidden;
  height: 500px;
}

.parallax-container .parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.parallax-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.parallax-text-container h1 {
  color: #fff;
  font-size: 100px; 
  font-family: "Playfair Display"; 
}


.parallax-text-container .professional-text {
    color: #fff5f5;
    font-size: 20px; 
    font-weight: bold;
    margin-top: 10px; 
  } */


.explore-button{
  background-image: linear-gradient(to right, #c4a197d0, #92564bc5); 
  padding-left: 50px !important; /* Adjust the padding to increase the width */
  padding-right: 50px !important;
  color: white !important;
}

.how-it-works-section{
  font-family: komu-a,sans-serif;
  background-color: hsla( 10.6508875739645,73.79912663755458%,55.09803921568628% ,1);
  color: hsla( 46.666666666666615,90.00000000000003%,96.07843137254902% ,1);
}

.img-carousel{
  min-width: 250px !important;
  min-height: 250px !important;
}

.img-card{
  width: 500px;
  height: 500px;
  object-fit:cover;
}