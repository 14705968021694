.map-container {
    position: relative;
    height: 100vh;
    width: 100%;
  }

.back-button{
  width: 100%;
}

#button-toggle-side-nav{
  position: absolute;
  z-index: 400;
  bottom: 20px;
  left: 20px;
  color: white;
  background-color:black
}

.sidebar {
  max-width: 400px;
  max-height: 600px;
  min-width: 260px;
  background-color: rgb(7, 7, 7);
  left: 10px;
  top: 10px;
  position: fixed;
  box-shadow: 0 2px 4px rgba(0,0,0,.5);
  z-index: 500;
  transition: transform 0.3s ease-out;
  transform: translateX(-110%);
}

.sidebar.active {
  transform: translateX(0);
}

.select-class{
  display: block;
}


.popup-content table {
  width: 100%;
  border-collapse: collapse;
}

.popup-content td:first-child {
  width: 30%;
  font-weight: bold;
}

.popup-content td:last-child {
  width: 70%;
  word-break: break-all;
}

.date-section{
  background-color:rgb(136, 136, 136);
}


.my-btn-class {
  display: block;
  margin: 0 auto;
  font-size: 1.2rem;
  padding: 0.8rem 1.5rem;
}


button#submit-button {
  display: block;
  margin: 0 auto;
  background-color:darkcyan;
  padding-bottom: 10px;
}

.collection{
  max-height: 500px;
  overflow-y: auto;

}

.collapsible{
  overflow-y: auto;
  color:black;
  background-color: #888888;
  word-wrap: break-word;
}

.collapsible-header{
  color: #888888;
  word-wrap: break-word;
}

.ul-collapsible{
  max-height: 300px;
}


.form-container {
  padding: 20px;
  background-color: #d6d4d4;
  border-radius: 5px;
}

.form-heading {
  font-size: 20px;
  margin-bottom: 20px;
  color: #020202;
}

.select-class {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.date-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.date-input {
  flex: 1;
  margin-right: 10px;
}

.my-datepicker {
  width: 100%;
  padding: 10px;
}

.submit-button {
  /* display: block; */
  width: 100%;
  padding: 10px;
  /* background-color: #020202; */
  /* color: #fff; */
  /* border: none; */
  /* border-radius: 5px; */
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.center {
  text-align: center;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.dismiss-button {
  width: 100%;
  /* padding: 10px 20px; */
  background-color: #fff !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.dismiss-button:hover {
  background-color: #f2f2f2;
}