.navbar {
    background-color: #ffffff00 !important;
}

.nav-link {
    color: black !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.img-profile {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
    transform: translateY(+15%);
}

.img-logo {
    max-height: 50px;
    position: absolute;
    top: 7px;
    left: 7px;
}


.sidenav-style {
    color: black;
}